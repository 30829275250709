/* 方法1：居中定位需要父标签 */
/* .container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login-form {
    width: 480px;
} */

/* 方法2：无需父标签，通过绝对定位 */

.login-form {
    background: #f1f6fe;
    width: 480px;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate3d(-50%, -50%, 0);
    -moz-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translate3d(-50%, -50%, 0);
    -o-transform: translate3d(-50%, -50%, 0);
    -webkit-transform: translate3d(-50%, -50%, 0);
}
